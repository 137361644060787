import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
import { useParams, Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import axios from "axios";
import { Helmet } from "react-helmet";
import ImageSlider from "../components/ImageSlider";
import useMediaQuery from "../components/useMediaQuery";

const CarDetails = () => {
  let { id } = useParams();
  const FD = useMediaQuery("(max-width: 1116px)");
  // const slides = [];
  // const { carId } = useAuthStore();
  const [car, setCar] = useState();
  const [currentCarti, setCurrentCarti] = useState("Vehicle Specs");
  useEffect(() => {
    getCar();
  }, []);
  // Function to update current carti
  const changeCarti = (cartiName) => {
    setCurrentCarti(cartiName);
  };
  const getCar = async () => {
    const res = await axios
      .post("https://backend.adraffmotorslimited.com/api/v1/cars/get", {
        slug: id,
      })
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;
      console.log(data);
      setCar(data);
    }
  };

  const containerStyles = {
    width: "100%",
    height: !FD ? "580px" : "180px",
    margin: "0 auto",
    position: "relative",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title</title>
        {/* <script src="assets/js/bootstrap.min.js" /> */}
      </Helmet>
      <PageTitle fTeaxt="Your" sText="Car" para={car?.make} />
      <section className="section" id="trainers">
        <div className="container">
          <br />
          <br />
          <div style={containerStyles}>
            <Carousel fade>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={car?.vehiclePhoto1}
                  style={{ borderRadius: 10 }}
                  alt="First slide"
                />
                {/* <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  src={car?.vehiclePhoto2}
                  style={{ borderRadius: 10, width: "100%", height: "100%" }}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  src={car?.vehiclePhoto3}
                  style={{ borderRadius: 10, width: "100%", height: "100%" }}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  src={car?.vehiclePhoto4}
                  style={{ borderRadius: 10, width: "100%", height: "100%" }}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  src={car?.vehiclePhoto5}
                  style={{ borderRadius: 10, width: "100%", height: "100%" }}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          {/* <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={car?.vehiclePhoto1}
                  style={{ borderRadius: 10 }}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={car?.vehiclePhoto2}
                  style={{ borderRadius: 10 }}
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={car?.vehiclePhoto3}
                  style={{ borderRadius: 10 }}
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div> */}

          <br />
          <br />
          <br />
          <br />

          <div className="row" id="tabs" style={{ marginTop: 100 }}>
            <div className="col-lg-4">
              <ul>
                <li>
                  <Link
                    to="#tabs-1"
                    style={{
                      textTransform: "capitalize",
                      width: "100%",
                      padding: "30px 30px",
                      display: "inline-block",
                      backgroundColor: "#fff",
                      boxShadow: " 0px 0px 15px rgba(0, 0, 0, 0.1)",
                      borderRadius: 5,
                      fontSize: 19,
                      color: "#232d39",
                      letterSpacing: 0.5,
                      fontWeight: "600",
                      transition: "all 0.3s",
                    }}
                    onClick={() => changeCarti("Vehicle Specs")}
                  >
                    <i
                      className="fa fa-cog"
                      style={{
                        fontSize: 32,
                        height: 32,
                        color: "#0043ff",
                        marginRight: 15,
                        display: "inline-block",
                      }}
                    ></i>{" "}
                    Vehicle Specs
                  </Link>
                </li>
                <li>
                  <Link
                    to="#tabs-2"
                    style={{
                      textTransform: "capitalize",
                      width: "100%",
                      padding: "30px 30px",
                      display: "inline-block",
                      backgroundColor: "#fff",
                      boxShadow: " 0px 0px 15px rgba(0, 0, 0, 0.1)",
                      borderRadius: 5,
                      fontSize: 19,
                      color: "#232d39",
                      letterSpacing: 0.5,
                      fontWeight: "600",
                      transition: "all 0.3s",
                    }}
                    onClick={() => changeCarti("Vehicle Description")}
                  >
                    <i
                      className="fa fa-info-circle"
                      style={{
                        fontSize: 32,
                        height: 32,
                        color: "#0043ff",
                        marginRight: 15,
                        display: "inline-block",
                      }}
                    ></i>{" "}
                    Vehicle Description
                  </Link>
                </li>
                <li>
                  <Link
                    to="#tabs-3"
                    style={{
                      textTransform: "capitalize",
                      width: "100%",
                      padding: "30px 30px",
                      display: "inline-block",
                      backgroundColor: "#fff",
                      boxShadow: " 0px 0px 15px rgba(0, 0, 0, 0.1)",
                      borderRadius: 5,
                      fontSize: 19,
                      color: "#232d39",
                      letterSpacing: 0.5,
                      fontWeight: "600",
                      transition: "all 0.3s",
                    }}
                    onClick={() => changeCarti("Vehicle Extras")}
                  >
                    <i
                      className="fa fa-plus-circle"
                      style={{
                        fontSize: 32,
                        height: 32,
                        color: "#0043ff",
                        marginRight: 15,
                        display: "inline-block",
                      }}
                    ></i>{" "}
                    Vehicle Extras
                  </Link>
                </li>
                <li>
                  <Link
                    to="#tabs-4"
                    style={{
                      textTransform: "capitalize",
                      width: "100%",
                      padding: "30px 30px",
                      display: "inline-block",
                      backgroundColor: "#fff",
                      boxShadow: " 0px 0px 15px rgba(0, 0, 0, 0.1)",
                      borderRadius: 5,
                      fontSize: 19,
                      color: "#232d39",
                      letterSpacing: 0.5,
                      fontWeight: "600",
                      transition: "all 0.3s",
                    }}
                    onClick={() => changeCarti("Contact Details")}
                  >
                    <i
                      className="fa fa-phone"
                      style={{
                        fontSize: 32,
                        height: 32,
                        color: "#0043ff",
                        marginRight: 15,
                        display: "inline-block",
                      }}
                    ></i>{" "}
                    Contact Details
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-8">
              <section className="tabs-content" style={{ width: "100%" }}>
                {currentCarti === "Vehicle Specs" && (
                  <article id="tabs-1">
                    <h4>Vehicle Specs</h4>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>Type</label>

                        <p>{car?.type}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Make</label>

                        <p>{car?.make}</p>
                      </div>

                      <div className="col-sm-6">
                        <label> Model</label>

                        <p>{car?.model}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>First registration</label>

                        <p>{car?.firstRegistration}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Mileage</label>

                        <p>{car?.mileage}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Fuel</label>

                        <p>{car?.fuel}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Engine size</label>

                        <p>{car?.engineSize}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Power</label>

                        <p>{car?.power}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Gearbox</label>

                        <p>{car?.gearbox}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Number of seats</label>

                        <p>{car?.numberOfSeats}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Doors</label>

                        <p>{car?.doors}</p>
                      </div>

                      <div className="col-sm-6">
                        <label>Color</label>

                        <p>{car?.color}</p>
                      </div>
                    </div>
                  </article>
                )}
                {currentCarti === "Vehicle Description" && (
                  <article id="tabs-2">
                    <h4>Vehicle Description</h4>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: car?.description.replace(
                          /(?:\r\n|\r|\n|\t)/g,
                          "<br />"
                        ),
                      }}
                    ></p>
                  </article>
                )}
                {currentCarti === "Vehicle Extras" && (
                  <article id="tabs-3">
                    <h4>Vehicle Extras</h4>

                    <div className="row">
                      <div className="col-sm-6">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: car?.vehicleExtras.replace(
                              /(?:\r\n|\r|\n|\t)/g,
                              "<br />"
                            ),
                          }}
                        ></p>
                      </div>
                      {/* <div className="col-sm-6">
                        <p>Leather seats</p>
                      </div>
                      <div className="col-sm-6">
                        <p>Power Assisted Steering</p>
                      </div>
                      <div className="col-sm-6">
                        <p>Electric heated seats</p>
                      </div>
                      <div className="col-sm-6">
                        <p>New HU and AU</p>
                      </div>
                      <div className="col-sm-6">
                        <p>Xenon headlights</p>
                      </div> */}
                    </div>
                  </article>
                )}
                {currentCarti === "Contact Details" && (
                  <article id="tabs-4">
                    <h4>Contact Details</h4>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>Name</label>

                        <p>{car?.contactDetailsName}</p>
                      </div>
                      <div className="col-sm-6">
                        <label>Phone</label>

                        <Link to="tel:+2349133099553">
                          {" "}
                          {car?.contactDetailsPhone}
                        </Link>
                      </div>
                      <div className="col-sm-6">
                        <label>Mobile phone</label>
                        <Link to="tel:+2349095501258"> 09095501258</Link>
                      </div>
                      <div className="col-sm-6">
                        <label>Email</label>
                        <p>
                          <Link to="mailto:adraffmotorslimited@gmail.com">
                            {car?.contactDetailsEmail}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </article>
                )}
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarDetails;
