import React from "react";
import CarsCallToAction from "../components/Cars/CarsCallToAction";
import CarsSearchForm from "../components/Cars/CarsSearchForm";
import { useAuthStore } from "../store/store";
import { Link } from "react-router-dom";
import Skeleton from "../components/Skeleton";

const Cars = () => {
  const { cars } = useAuthStore();
  return (
    <>
      {/* Na here  Call to Action Start */}
      <CarsCallToAction />
      {/* Na here  Call to Action End */}
      {/* Na here Fleet Starts */}
      <section className="section" id="trainers">
        <div className="container">
          <br />
          <br />
          <CarsSearchForm />
          <div className="row">
            {cars[0] ? (
              <>
                {" "}
                {cars?.map((dataItem) => {
                  return (
                    <div className="col-lg-4">
                      <div className="trainer-item">
                        <div className="image-thumb">
                          <img src={dataItem.vehiclePhoto1} alt="car" />
                        </div>
                        <div className="down-content">
                          <span>{dataItem.make}</span>

                          <h4>{dataItem.model}</h4>

                          <p>
                            <i className="fa fa-dashboard"></i>{" "}
                            {dataItem.mileage}
                            &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-cube"></i> {dataItem.power}
                            &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-cog"></i> {dataItem.gearbox}
                          </p>

                          <ul className="social-icons">
                            <li>
                              <Link to={`/CarDetails/${dataItem.slug}`}>
                                + View Car
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {" "}
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                      <Skeleton height="250px" width="100%" />
                    </div>
                    <div className="down-content">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 15,
                          marginBottom: 15,
                          rowGap: 15,
                        }}
                      >
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="20px" width="200px" />
                      </div>

                      <p>
                        <i className="fa fa-dashboard"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cube"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                        &nbsp;&nbsp;&nbsp;
                        <i className="fa fa-cog"></i>{" "}
                        <Skeleton height="10px" width="50px" />
                      </p>

                      <ul className="social-icons">
                        <li>
                          <Link>+ View Car</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <div className="col-lg-4">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src="assets/images/product-2-720x480.jpg" alt="" />
                </div>
                <div className="down-content">
                  <span>
                    <del>
                      <sup>$</sup>11999{" "}
                    </del>{" "}
                    &nbsp; <sup>$</sup>11779
                  </span>

                  <h4>Lorem ipsum dolor sit amet, consectetur</h4>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>

                  <ul className="social-icons">
                    <li>
                      <a to="car-details.html">+ View Car</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src="assets/images/product-3-720x480.jpg" alt="" />
                </div>
                <div className="down-content">
                  <span>
                    <del>
                      <sup>$</sup>11999{" "}
                    </del>{" "}
                    &nbsp; <sup>$</sup>11779
                  </span>

                  <h4>Lorem ipsum dolor sit amet, consectetur</h4>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>

                  <ul className="social-icons">
                    <li>
                      <a to="car-details.html">+ View Car</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src="assets/images/product-4-720x480.jpg" alt="" />
                </div>
                <div className="down-content">
                  <span>
                    <del>
                      <sup>$</sup>11999{" "}
                    </del>{" "}
                    &nbsp; <sup>$</sup>11779
                  </span>

                  <h4>Lorem ipsum dolor sit amet, consectetur</h4>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>

                  <ul className="social-icons">
                    <li>
                      <a to="car-details.html">+ View Car</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src="assets/images/product-5-720x480.jpg" alt="" />
                </div>
                <div className="down-content">
                  <span>
                    <del>
                      <sup>$</sup>11999{" "}
                    </del>{" "}
                    &nbsp; <sup>$</sup>11779
                  </span>

                  <h4>Lorem ipsum dolor sit amet, consectetur</h4>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>

                  <ul className="social-icons">
                    <li>
                      <a to="car-details.html">+ View Car</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src="assets/images/product-6-720x480.jpg" alt="" />
                </div>
                <div className="down-content">
                  <span>
                    <del>
                      <sup>$</sup>11999{" "}
                    </del>{" "}
                    &nbsp; <sup>$</sup>11779
                  </span>

                  <h4>Lorem ipsum dolor sit amet, consectetur</h4>

                  <p>
                    <i className="fa fa-dashboard"></i> 130 000km
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cube"></i> 1800 cc &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-cog"></i> Manual &nbsp;&nbsp;&nbsp;
                  </p>

                  <ul className="social-icons">
                    <li>
                      <a to="car-details.html">+ View Car</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>

          <br />

          <nav>
            <ul className="pagination pagination-lg justify-content-center">
              <li className="page-item">
                <a className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" to="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" to="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" to="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      {/* Na here Fleet Ends */}
    </>
  );
};

export default Cars;
