import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import axios from "axios";
const Faq = () => {
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    const res = await axios
      .get("https://backend.adraffmotorslimited.com/api/v1/faq/allFAQ")
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;
      setFaq(data);
    }
  };
  return (
    <>
      <PageTitle
        fTeaxt="READ OUR"
        sText="FAQ"
        para="Here are some frequently asked questions (FAQ) related to car purchasing"
      />
      <section className="section" id="our-classes">
        <div className="container">
          <br />
          <br />
          <br />

          <section className="tabs-content">
            <article>
              {faq?.map((dataItem) => {
                return (
                  <>
                    <h4 key={dataItem._id}>
                      <i className="fa fa-question-circle"></i>{" "}
                      {dataItem.question}
                    </h4>
                    <p>{dataItem.anwer}</p>
                    <br />
                  </>
                );
              })}
            </article>
          </section>
        </div>
      </section>
    </>
  );
};

export default Faq;
