import React from "react";
import { Link } from "react-router-dom";
import video from "./off.mp4";
const HomeMain = () => {
  return (
    <div className="main-banner" id="top">
      <video autoPlay muted loop id="bg-video">
        <source src={video} type="video/mp4" />
      </video>

      <div className="video-overlay header-text">
        <div className="caption">
          <h6>
            Adraff <em>Motors</em> Limited
          </h6>
          <h2>
            Dream aesthetics, <em>ride</em> aesthetics
          </h2>
          <div className="main-button">
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
