import React from "react";
import PageTitle from "../components/PageTitle";
import CEO from "../ceo.jpg";
import Manager from "../Manager.jpg";
import Secretary from "../secr.jpg";
import Emi from "../emi.jpg";
const Team = () => {
  return (
    <>
      <PageTitle
        fTeaxt="MEET OUR"
        sText="TEAM"
        para="Meet the incredible team behind Adraff Motors Limited! "
      />
      <section className="section" id="trainers">
        <div className="container">
          <br />
          <br />
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src={CEO} alt="CEO" />
                </div>
                <div className="down-content">
                  <span>CEO</span>
                  <h4>Alh. Fatai rafiu adio</h4>
                  {/* <p>
                    Vestibulum id est eu felis vulputate hendrerit. Suspendisse
                    dapibus turpis in dui pulvinar imperdiet. Nunc consectetur.
                  </p>
                  <ul className="social-icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-behance"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src={Manager} alt="Manager" />
                </div>
                <div className="down-content">
                  <span>Manager</span>
                  <h4>Ogunkanbi S. Oladayo</h4>
                  {/* <p>
                    Vestibulum id est eu felis vulputate hendrerit. Suspendisse
                    dapibus turpis in dui pulvinar imperdiet. Nunc consectetur.
                  </p>
                  <ul className="social-icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-behance"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src={Secretary} alt="Secretary" />
                </div>
                <div className="down-content">
                  <span>Secretary</span>
                  <h4>Mba Chisom</h4>
                  {/* <p>
                    Vestibulum id est eu felis vulputate hendrerit. Suspendisse
                    dapibus turpis in dui pulvinar imperdiet. Nunc consectetur.
                  </p>
                  <ul className="social-icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-behance"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="trainer-item">
                <div className="image-thumb">
                  <img src={Emi} alt="" />
                </div>
                <div className="down-content">
                  <span>Social media manager / content creator</span>
                  <h4>Ehibor I. Emmanuel</h4>
                  {/* <p>
                    Vestibulum id est eu felis vulputate hendrerit. Suspendisse
                    dapibus turpis in dui pulvinar imperdiet. Nunc consectetur.
                  </p>
                  <ul className="social-icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-behance"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
