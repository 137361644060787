import React, { useState, useEffect } from "react";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import axios from "axios";
const Blog = () => {
  const [currentBlog, setCurrentBlog] = useState("Bolg-1");
  const [searchTerm, setSearchTerm] = useState("");
  const [blog, setBlog] = useState([]);
  //h
  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    const res = await axios
      .post(`https://backend.adraffmotorslimited.com/api/v1/blog/getAllBlog`)
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;
      setBlog(data);
    }
  };

  return (
    <>
      <PageTitle
        fTeaxt="READ OUR"
        sText="Blog"
        para="Welcome to our blog section, where curiosity meets insight and knowledge ignites inspiration. "
      />
      <section className="section" id="our-classes">
        <div className="container">
          <br />
          <br />
          <div className="row">
            <div className="col-lg-8">
              <section className="tabs-content">
                {blog?.length === 0 ? (
                  <h1>Loading</h1>
                ) : (
                  blog
                    ?.filter((data) => {
                      if (searchTerm === "") {
                        return data;
                      } else if (
                        data?.title
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return data;
                      }
                    })
                    .map((car, index) => (
                      <article
                        id="tabs-1"
                        key={car._id}
                        style={{ marginBottom: 50 }}
                      >
                        <img src={car?.image} alt="blogImg" />
                        <h4>{car.title}</h4>

                        <p>
                          <i className="fa fa-user"></i> {car?.userName}{" "}
                          &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                          {new Date(car?.createdAt).toLocaleString()}
                          &nbsp;|&nbsp; <i className="fa fa-comments"></i> 15
                          comments
                        </p>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: car?.content
                              .slice(0, 270)
                              .replace(/(?:\r\n|\r|\n|\t)/g, "<br />"),
                          }}
                        ></p>
                        <div className="main-button">
                          <Link to={`/BlogDetails/${car?.slug}`}>
                            Continue Reading
                          </Link>
                        </div>
                      </article>
                    ))
                )}
              </section>
            </div>

            <div className="col-lg-4">
              <h5 className="h5">Search</h5>

              <div className="contact-form">
                <form id="search_form" name="gs" method="GET" action="#">
                  <input
                    type="text"
                    name="q"
                    className="searchText"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="type to search..."
                    autocomplete="on"
                  />
                </form>
              </div>

              <h5 className="h5">Recent posts</h5>

              <ul>
                <li>
                  <p>
                    <Link
                      to={`/BlogDetails/${blog[0]?.slug}`}
                      // onClick={() => setCurrentBlog("Bolg-1")}
                    >
                      {blog[0]?.title}
                    </Link>
                  </p>
                  <small>
                    <i className="fa fa-user"></i> {blog[0]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(blog[0]?.createdAt).toLocaleString()}
                  </small>
                </li>

                <li>
                  <br />
                </li>

                <li>
                  <p>
                    <Link
                      to={`/BlogDetails/${blog[1]?.slug}`}
                      // onClick={() => setCurrentBlog("Bolg-2")}
                    >
                      {blog[1]?.title}
                    </Link>
                  </p>
                  <small>
                    <i className="fa fa-user"></i> {blog[1]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(blog[1]?.createdAt).toLocaleString()}
                  </small>
                </li>

                <li>
                  <br />
                </li>

                <li>
                  <p>
                    <Link
                      to={`/BlogDetails/${blog[2]?.slug}`}
                      // onClick={() => setCurrentBlog("Bolg-3")}
                    >
                      {blog[2]?.title}
                    </Link>
                  </p>

                  <small>
                    <i className="fa fa-user"></i> {blog[2]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(blog[2]?.createdAt).toLocaleString()}
                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
