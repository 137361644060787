import React from "react";
import { Outlet } from "react-router-dom";
import Preloader from "../../components/Preloader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const DashboardLayout = () => {
  return (
    <>
      {/* Na here Preloader Start */}
      {/* <Preloader /> */}
      {/* Na here Preloader End */}
      {/* Na here Header Area Start  */}
      <Header />
      {/* Na here Header Area End  */}
      <Outlet />
      <Footer />
    </>
  );
};

export default DashboardLayout;
