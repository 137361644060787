import React from "react";
import { Link } from "react-router-dom";
const ContactFeatures = () => {
  return (
    <section className="section" id="features">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                contact <em> info</em>
              </h2>
              <img src="assets/images/line-dec.png" alt="waves" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="icon">
              <i className="fa fa-phone"></i>
            </div>

            <h5>
              <Link to="tel:+2349095501258">+234 909 550 1258</Link>
              <br />
              <br />
              <Link to="tel:+2349133099553">+234 913 309 9553</Link>
            </h5>

            <br />
          </div>

          <div className="col-md-4">
            <div className="icon">
              <i className="fa fa-envelope"></i>
            </div>

            <h5>
              <Link to="mailto:adraffmotorslimited@gmail.com">
                adraffmotorslimited@gmail.com
              </Link>
            </h5>

            <br />
          </div>

          <div className="col-md-4">
            <div className="icon">
              <i className="fa fa-map-marker"></i>
            </div>

            <h5>Plot 470 jahi Katampe Road</h5>

            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFeatures;
