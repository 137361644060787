import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "../Skeleton";

const HomeCars = ({ data }) => {
  return (
    <section className="section" id="trainers">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                Featured <em>Cars</em>
              </h2>
              <img src="assets/images/line-dec.png" alt="" />
              <p>
                Get ready to elevate your driving experience with our handpicked
                selection of premium vehicles. From sleek sedans to rugged SUVs,
                we've curated a collection that combines style, performance, and
                reliability.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {data[0] ? (
            <>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <img src={data[0]?.vehiclePhoto1} alt="car" />
                  </div>
                  <div className="down-content">
                    <span>{data[0]?.make}</span>

                    <h4> {data[0]?.model}</h4>

                    <p>
                      <i className="fa fa-dashboard"></i> {data[0]?.mileage}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i> {data[0]?.power}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i> {data[0]?.gearbox}
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link to={`/CarDetails/${data[0]?.slug}`}>
                          + View Car
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <img src={data[1]?.vehiclePhoto1} alt="car" />
                  </div>
                  <div className="down-content">
                    <span>{data[1]?.make}</span>

                    <h4>{data[1]?.model}</h4>

                    <p>
                      <i className="fa fa-dashboard"></i> {data[1]?.mileage}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i> {data[1]?.power}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i> {data[1]?.gearbox}
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link to={`/CarDetails/${data[1]?.slug}`}>
                          + View Car
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <img src={data[2]?.vehiclePhoto1} alt="car" />
                  </div>
                  <div className="down-content">
                    <span>{data[2]?.make}</span>

                    <h4>{data[2]?.model}</h4>

                    <p>
                      <i className="fa fa-dashboard"></i> {data[2]?.mileage}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i> {data[2]?.power}
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i> {data[2]?.gearbox}
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link to={`/CarDetails/${data[2]?.slug}`}>
                          + View Car
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <Skeleton height="250px" width="100%" />
                  </div>
                  <div className="down-content">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 15,
                        marginBottom: 15,
                        rowGap: 15,
                      }}
                    >
                      <Skeleton height="10px" width="50px" />
                      <Skeleton height="20px" width="200px" />
                    </div>

                    <p>
                      <i className="fa fa-dashboard"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link>+ View Car</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <Skeleton height="250px" width="100%" />
                  </div>
                  <div className="down-content">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 15,
                        marginBottom: 15,
                        rowGap: 15,
                      }}
                    >
                      <Skeleton height="10px" width="50px" />
                      <Skeleton height="20px" width="200px" />
                    </div>

                    <p>
                      <i className="fa fa-dashboard"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link>+ View Car</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trainer-item">
                  <div className="image-thumb">
                    <Skeleton height="250px" width="100%" />
                  </div>
                  <div className="down-content">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 15,
                        marginBottom: 15,
                        rowGap: 15,
                      }}
                    >
                      <Skeleton height="10px" width="50px" />
                      <Skeleton height="20px" width="200px" />
                    </div>

                    <p>
                      <i className="fa fa-dashboard"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cube"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                      &nbsp;&nbsp;&nbsp;
                      <i className="fa fa-cog"></i>{" "}
                      <Skeleton height="10px" width="50px" />
                    </p>

                    <ul className="social-icons">
                      <li>
                        <Link>+ View Car</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <br />

        <div className="main-button text-center">
          <Link to="/cars">View Cars</Link>
        </div>
      </div>
    </section>
  );
};

export default HomeCars;
