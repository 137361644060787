import React from "react";
import { Link } from "react-router-dom";
const HomeTestimonials = () => {
  return (
    <section className="section" id="features">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                Read our <em>Testimonials</em>
              </h2>
              <img src="assets/images/line-dec.png" alt="waves" />
              <p>
                Discover what our satisfied customers have to say about their
                experiences with us!
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="features-items">
              <li className="feature-item">
                <div class="left-icon">
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      float: "left",
                      marginRight: 30,
                      borderRadius: 10,
                      backgroundColor: "#0043ff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="right-content">
                  <h4>Sarah M.</h4>
                  <p>
                    <em>
                      "I had an amazing experience purchasing my new car from
                      this company. The staff was incredibly knowledgeable and
                      helpful throughout the entire process. I couldn't be
                      happier with my purchase!"
                    </em>
                  </p>
                </div>
              </li>
              <li className="feature-item">
                <div class="left-icon">
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      float: "left",
                      marginRight: 30,
                      borderRadius: 10,
                      backgroundColor: "#0043ff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="right-content">
                  <h4> Michael D.</h4>
                  <p>
                    <em>
                      "The team at this car dealership went above and beyond to
                      find the perfect vehicle for me within my budget. Their
                      dedication and professionalism truly made the buying
                      process seamless and enjoyable."
                    </em>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="features-items">
              <li className="feature-item">
                <div class="left-icon">
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      float: "left",
                      marginRight: 30,
                      borderRadius: 10,
                      backgroundColor: "#0043ff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="right-content">
                  <h4>John P.</h4>
                  <p>
                    <em>
                      "I've bought several cars from this company over the
                      years, and each time they exceed my expectations. Their
                      commitment to customer satisfaction and quality vehicles
                      keeps me coming back."
                    </em>
                  </p>
                </div>
              </li>
              <li className="feature-item">
                <div class="left-icon">
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      float: "left",
                      marginRight: 30,
                      borderRadius: 10,
                      backgroundColor: "#0043ff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="right-content">
                  <h4>Aisha S.</h4>
                  <p>
                    <em>
                      "From the moment I walked in, I felt welcomed and valued
                      as a customer. The sales team listened to my needs and
                      preferences, and I drove off the lot in my dream car. I
                      highly recommend this company to anyone in the market for
                      a new vehicle."
                    </em>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <br />

        <div className="main-button text-center">
          <Link to="/Testimonials">Read More</Link>
        </div>
      </div>
    </section>
  );
};

export default HomeTestimonials;
