import React from "react";
import PageTitle from "../components/PageTitle";

const Testimonials = () => {
  return (
    <>
      <PageTitle
        fTeaxt="READ OUR"
        sText="TESTIMONIALS"
        para="Discover what our satisfied customers have to say about their experiences with us! Our testimonials showcase real stories from individuals who have benefited from our products/services."
      />
      <section class="section" id="features">
        <div class="container">
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col-lg-6">
              <ul class="features-items">
                <li class="feature-item">
                  <div class="left-icon">
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        float: "left",
                        marginRight: 30,
                        borderRadius: 10,
                        backgroundColor: "#0043ff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        fill="#fff"
                        viewBox="0 0 256 256"
                      >
                        <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="right-content">
                    <h4> Muhammad T.</h4>
                    <p>
                      <em>
                        "I was hesitant about buying a car, but the team at this
                        dealership made it easy and enjoyable. They were
                        patient, transparent, and genuinely cared about finding
                        the right car for me. I'm grateful for their expertise
                        and would recommend them to anyone."
                      </em>
                    </p>
                  </div>
                </li>
                <li class="feature-item">
                  <div class="left-icon">
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        float: "left",
                        marginRight: 30,
                        borderRadius: 10,
                        backgroundColor: "#0043ff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        fill="#fff"
                        viewBox="0 0 256 256"
                      >
                        <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="right-content">
                    <h4>Olu W.</h4>
                    <p>
                      <em>
                        "This company sets the standard for exceptional customer
                        service in the automotive industry. From the moment I
                        reached out, they were responsive and accommodating to
                        my needs. I'm incredibly satisfied with my purchase and
                        the level of professionalism they exhibited throughout
                        the entire process."
                      </em>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul class="features-items">
                <li class="feature-item">
                  <div class="left-icon">
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        float: "left",
                        marginRight: 30,
                        borderRadius: 10,
                        backgroundColor: "#0043ff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        fill="#fff"
                        viewBox="0 0 256 256"
                      >
                        <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="right-content">
                    <h4>Ngozi H.</h4>
                    <p>
                      <em>
                        "My experience buying a car from this dealership was
                        nothing short of fantastic. The staff was friendly,
                        knowledgeable, and not pushy at all. They helped me find
                        the perfect vehicle for my lifestyle and budget. I'll
                        definitely be returning for my future car needs."
                      </em>
                    </p>
                  </div>
                </li>
                <li class="feature-item">
                  <div class="left-icon">
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        float: "left",
                        marginRight: 30,
                        borderRadius: 10,
                        backgroundColor: "#0043ff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        fill="#fff"
                        viewBox="0 0 256 256"
                      >
                        <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="right-content">
                    <h4>Fatima K.</h4>
                    <p>
                      <em>
                        "I can't thank this company enough for making my car
                        buying experience so pleasant. They provided excellent
                        guidance, answered all my questions, and made sure I
                        drove away with a smile on my face. If you're in the
                        market for a new car, look no further!"
                      </em>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
