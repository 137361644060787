import React, { useEffect, useState } from "react";
import HomeMain from "../components/home/HomeMain";
import HomeCars from "../components/home/HomeCars";
import HomeAboutUs from "../components/home/HomeAboutUs";
import HomeBLOG from "../components/home/HomeBLOG";
import HomeMessageUs from "../components/home/HomeMessageUs";
import HomeTestimonials from "../components/home/HomeTestimonials";
// import { Helmet } from "react-helmet";
import axios from "axios";

const Home = () => {
  const [cars, setCars] = useState([]);
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getCar();
    getBlogs();
  }, []);

  const getBlogs = async () => {
    const res = await axios
      .post(`https://backend.adraffmotorslimited.com/api/v1/blog/getAllBlog`)
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;
      setBlog(data);
    }
  };
  const getCar = async () => {
    const res = await axios
      .post("https://backend.adraffmotorslimited.com/api/v1/cars/search", {
        make: "",
        fuel: "",
        type: "",
        gearbox: "",
      })
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;
      console.log(data);
      setCars(data);
    }
  };
  return (
    <>
      <div className="application">
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet> */}
      </div>
      {/* Na here Main Banner Area Start */}
      <HomeMain />
      {/* Na here Main Banner Area End */}
      {/* Na here Cars Starts */}
      <HomeCars data={cars} />
      {/* Na here Cars End */}
      {/* Na here About Us Starts */}
      <HomeAboutUs />
      {/* Na here About Us End */}
      {/* Na here Blog Start */}
      <HomeBLOG data={blog} />
      {/* Na here Blog End */}
      {/* Na here  Call to Action Start */}
      <HomeMessageUs />
      {/* Na here  Call to Action End */}
      {/* Na here Testimonials Item Start */}
      <HomeTestimonials />
      {/* Na here Testimonials Item End */}
    </>
  );
};

export default Home;
