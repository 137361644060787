import React, { useState } from "react";

const OurClasses = () => {
  const [about, setAbout] = useState("Our Goals");
  return (
    <section className="section" id="our-classes">
      <div className="container">
        <br />
        <br />
        <br />
        <div className="row" id="tabs">
          <div className="col-lg-4">
            <ul>
              <li>
                <a href="#tabs-1" onClick={() => setAbout("Our Goals")}>
                  <i className="fa fa-soccer-ball-o"></i> Our Goals
                </a>
              </li>
              <li>
                <a href="#tabs-2" onClick={() => setAbout("Our Work")}>
                  <i className="fa fa-briefcase"></i> Our Work
                </a>
              </li>
              <li>
                <a href="#tabs-3" onClick={() => setAbout("Our Passion")}>
                  <i className="fa fa-heart"></i> Our Passion
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-8">
            <section className="tabs-content">
              {about === "Our Goals" && (
                <article id="tabs-1">
                  <img src="assets/images/about-image-1-940x460.jpg" alt="" />
                  <h4>Our Goals</h4>

                  <p>
                    Adraff Motors Limited stands as a beacon of excellence in
                    the automotive industry, offering unparalleled service and
                    an extensive range of high-quality vehicles.
                  </p>

                  <p>
                    Established with a vision to redefine the car buying
                    experience, we pride ourselves on our commitment to customer
                    satisfaction, professionalism, and integrity.
                  </p>

                  <p>
                    At Adraff Motors Limited, our mission is simple: to provide
                    our customers with the finest vehicles and exceptional
                    service that exceed their expectations. We strive to create
                    lasting relationships built on trust, transparency, and
                    reliability.
                  </p>

                  <p>
                    Experience the difference that Adraff Motors Limited can
                    make in your car buying journey. Visit our showroom today to
                    explore our impressive inventory, meet our friendly team,
                    and discover why we are the preferred choice for automotive
                    solutions. We look forward to serving you with excellence at
                    Adraff Motors Limited.
                  </p>
                </article>
              )}
              {about === "Our Work" && (
                <article id="tabs-2">
                  <img src="assets/images/about-image-2-940x460.jpg" alt="" />
                  <h4>Our Work</h4>
                  <p>
                    We believe that everyone deserves access to quality vehicles
                    at fair prices. That's why we strive to offer competitive
                    pricing and flexible financing options to accommodate a
                    range of budgets and needs.
                  </p>
                  <p>
                    At Adraff Motors Limited, our customers are our top
                    priority. From your initial inquiry to long after you drive
                    off the lot, we are committed to providing attentive,
                    personalized service that exceeds your expectations.
                  </p>

                  <p>
                    With years of experience in the automotive industry, our
                    team possesses the knowledge and expertise to assist you in
                    finding the perfect vehicle and navigating the purchasing
                    process with ease.
                  </p>
                </article>
              )}
              {about === "Our Passion" && (
                <article id="tabs-3">
                  <img src="assets/images/about-image-3-940x460.jpg" alt="" />
                  <h4>Our Passion</h4>
                  <p>
                    Adraff Motors Limited offers an extensive selection of
                    top-tier vehicles, ranging from luxury sedans and SUVs to
                    efficient compact cars and rugged trucks. Whether you're in
                    the market for a brand-new model or a meticulously
                    maintained pre-owned vehicle, we have options to suit every
                    taste and budget.
                  </p>

                  <p>
                    We uphold the highest standards of quality in all aspects of
                    our operations, from the vehicles we offer to the services
                    we provide. Every vehicle in our inventory undergoes
                    rigorous inspection to ensure its reliability and
                    performance.
                  </p>

                  <p>
                    Honesty and integrity are at the core of everything we do.
                    We believe in transparent communication and fair business
                    practices, earning the trust and loyalty of our customers.
                  </p>
                </article>
              )}
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClasses;
