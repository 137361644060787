import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./App.css";
import alanBtn from "@alan-ai/alan-sdk-web";
import DashboardLayout from "./layouts/dashboard";
import Page404 from "./pages/Page404";
import Home from "./pages/Home";
import Cars from "./pages/Cars";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import Terms from "./pages/Terms";
import Testimonials from "./pages/Testimonials";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import CarDetails from "./pages/CarDetails";
import BlogDetails from "./pages/BlogDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="/Home" replace />, index: true },
      { path: "Home", element: <Home /> },
      { path: "Cars", element: <Cars /> },
      { path: "Contact", element: <Contact /> },
      { path: "AboutUs", element: <AboutUs /> },
      { path: "Team", element: <Team /> },
      { path: "Terms", element: <Terms /> },
      { path: "Testimonials", element: <Testimonials /> },
      { path: "Faq", element: <Faq /> },
      { path: "CarDetails/:id", element: <CarDetails /> },
      { path: "Blog", element: <Blog /> },
      { path: "BlogDetails/:slug", element: <BlogDetails /> },
      { path: "404", element: <Page404 /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },

  { path: "*", element: <Navigate to="/404" replace /> },
]);

function App() {
  useEffect(() => {
    alanBtn({
      key: "8037ab407ee81fc0e01405cba36b2a572e956eca572e1d8b807a3e2338fdd0dc/stage",
      onCommand: (commandData) => {
        if (commandData.command === "go:back") {
          // Call the client code that will react to the received command
          // c0179ca9b0fad284e01405cba36b2a572e956eca572e1d8b807a3e2338fdd0dc/stage
          // 8037ab407ee81fc0e01405cba36b2a572e956eca572e1d8b807a3e2338fdd0dc/stage
        }
      },
    });
  }, []);
  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
