import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo2.png";
const Header = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [active, setActive] = useState(false);

  // Function to update current page
  const changePage = (pageName) => {
    setCurrentPage(pageName);
  };
  return (
    <header className="header-area header-sticky">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              {/* Na here Logo Start  */}
              <Link
                to="/"
                className="logo"
                onClick={() => changePage("home")}
                style={{ position: "relative" }}
              >
                <img
                  src={Logo}
                  style={{ marginBottom: 15 }}
                  width={170}
                  alt="logo"
                />
                <div
                  style={{
                    fontSize: 10,
                    position: "absolute",
                    display: "flex",
                    top: 25,
                    left: 40,
                  }}
                >
                  Adraff <em> Motors</em>
                </div>
              </Link>
              {/* Na here Logo End  */}
              {/* Na here Menu Start */}
              <ul
                className="nav"
                style={{ display: active ? "block" : "none" }}
              >
                <li>
                  <Link
                    to="Home"
                    className={currentPage === "home" && "active"}
                    onClick={() => {
                      changePage("home");
                      setActive(!active);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="Cars"
                    className={currentPage === "Cars" && "active"}
                    onClick={() => {
                      changePage("Cars");
                      setActive(!active);
                    }}
                  >
                    Cars
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    className={`dropdown-toggle ${
                      currentPage === "About" && "active"
                    }`}
                    data-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      changePage("About");
                      // setActive(!active);
                    }}
                  >
                    About
                  </Link>

                  <div className="dropdown-menu">
                    <Link
                      className="dropdown-item"
                      to="AboutUs"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      About Us
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="Blog"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      Blog
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="Team"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      Team
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="Testimonials"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      Testimonials
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="FAQ"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      FAQ
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="Terms"
                      onClick={() => {
                        changePage("About");
                        setActive(!active);
                      }}
                    >
                      Terms
                    </Link>
                  </div>
                </li>
                <li>
                  <Link
                    to="Contact"
                    className={currentPage === "Contact" && "active"}
                    onClick={() => {
                      changePage("Contact");
                      setActive(!active);
                    }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div
                className={`menu-trigger ${active && "active"}`}
                onClick={() => setActive(!active)}
              >
                <span>Menu</span>
              </div>
              {/* Na here Menu End  */}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
