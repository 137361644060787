import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthStore } from "../../store/store";

const CarsSearchForm = () => {
  const { setCars } = useAuthStore();
  const [formData, setFormData] = useState({
    make: "",
    fuel: "",
    type: "",
    gearbox: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const getCar = async () => {
      const res = await axios
        .post(
          "https://backend.adraffmotorslimited.com/api/v1/cars/search",
          formData
        )
        .catch((err) => {
          console.log(err, err?.response?.data);
        });

      if (res) {
        const data = await res?.data;
        console.log(data);
        setCars(data);
      }
    };
    getCar();
  }, [formData]);

  // console.log(formData);
  return (
    <div className="contact-form">
      <form action="#" id="contact">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Used/New:</label>

              <select
                id="Type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Select a type</option>
                <option value="Used vehicle">Used vehicle</option>
                <option value="New vehicle">New vehicle</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Fuel:</label>

              <select
                id="Fuel"
                name="fuel"
                value={formData.fuel}
                onChange={handleChange}
                required
              >
                <option value="">Select a Fuel</option>
                <option value="Diesel">Diesel</option>
                <option value="Petrol">Petrol</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Make:</label>

              <select
                id="Make"
                name="make"
                value={formData.make}
                onChange={handleChange}
                required
              >
                <option value="">Select a make</option>
                <option value="Toyota">Toyota</option>
                <option value="Honda">Honda</option>
                <option value="Ford">Ford</option>
                <option value="Chevrolet">Chevrolet</option>
                <option value="Volkswagen">Volkswagen</option>
                <option value="Nissan">Nissan</option>
                <option value="Mercedes-Benz">Mercedes-Benz</option>
                <option value="BMW">BMW</option>
                <option value="Audi">Audi</option>
                <option value="Hyundai">Hyundai</option>
                <option value="Kia">Kia</option>
                <option value="Subaru">Subaru</option>
                <option value="Lexus">Lexus</option>
                <option value="Jeep">Jeep</option>
                <option value="Lamborghini">Lamborghini</option>
                <option value="Ferrari">Ferrari</option>
                <option value="Porsche">Porsche</option>
                <option value="Peugeot">Peugeot</option>
                <option value="Range-Rover">Range-Rover</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Gearbox:</label>

              <select
                id="Gearbox"
                name="gearbox"
                value={formData.gearbox}
                onChange={handleChange}
                required
              >
                <option value="">Select a Gearbox</option>
                <option value="Manual">Manual</option>
                <option value="Automatic">Automatic</option>
              </select>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Price:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Mileage:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Engine size:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Power:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Fuel:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Gearbox:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Doors:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Number of seats:</label>

              <select>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
                <option value="">-- All --</option>
              </select>
            </div>
          </div> */}
        </div>

        <div className="col-sm-4 offset-sm-4">
          <div className="main-button text-center">
            <a to="#">Search</a>
          </div>
        </div>
        <br />
        <br />
      </form>
    </div>
  );
};

export default CarsSearchForm;
