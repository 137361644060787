import React from "react";
import PageTitle from "../components/PageTitle";

const Terms = () => {
  return (
    <>
      <PageTitle
        fTeaxt="READ OUR"
        sText="TERMS"
        para="Welcome to our Terms and Conditions page! Here, you'll find important information about using our services/products."
      />
      <section class="section" id="our-classes">
        <div class="container">
          <br />
          <br />
          <br />

          <section class="tabs-content">
            <article>
              <h4>1. MSRP (Manufacturer's Suggested Retail Price):</h4>
              <p>
                The price suggested by the manufacturer for selling a vehicle.
              </p>

              <br />

              <h4>2. Down Payment:</h4>
              <p>
                The initial payment made in cash or trade-in value when
                purchasing a vehicle, which is subtracted from the total
                purchase price.
              </p>

              <br />

              <h4>3. Financing:</h4>
              <p>
                The process of borrowing money from a financial institution to
                purchase a vehicle, usually repaid in monthly installments with
                interest.
              </p>

              <br />

              <h4>4. Leasing:</h4>
              <p>
                An alternative to purchasing a vehicle where the lessee pays for
                the use of the vehicle for a specific period, typically with
                lower monthly payments compared to financing.
              </p>
              <br />

              <h4>5. APR (Annual Percentage Rate):</h4>
              <p>
                The annualized interest rate charged on a loan or financing
                agreement.
              </p>
              <br />

              <h4>6. Trade-In Value:</h4>
              <p>
                The amount of money a dealership offers for a customer's old
                vehicle when purchasing a new one.
              </p>
              <br />

              <h4>7. Depreciation:</h4>
              <p>
                The decrease in value of a vehicle over time due to factors such
                as age, mileage, and wear and tear.
              </p>
              <br />

              <h4>8. Extended Warranty:</h4>
              <p>
                Additional coverage purchased to protect against unexpected
                repair costs beyond the manufacturer's warranty period.
              </p>
              <br />

              <h4>9. Title:</h4>
              <p>Legal documentation proving ownership of a vehicle.</p>
              <br />

              <h4>10. VIN (Vehicle Identification Number):</h4>
              <p>
                A unique code assigned to every vehicle for identification
                purposes, which contains information about the vehicle's
                manufacturer, specifications, and history.
              </p>
              <br />

              <h4>11. Pre-Owned:</h4>
              <p>
                Used vehicles that have been previously owned by one or more
                individuals before being offered for sale.
              </p>
              <br />

              <h4>12. Certified Pre-Owned (CPO):</h4>
              <p>
                Used vehicles that have undergone a thorough inspection and meet
                specific criteria set by the manufacturer or dealership to
                ensure quality and reliability.
              </p>
              <br />

              <h4>13. Lemon Law:</h4>
              <p>
                Laws that protect consumers who purchase defective vehicles,
                typically by requiring the manufacturer to repair or replace the
                vehicle if it has significant defects.
              </p>
              <br />

              <h4>14. Trade-In Allowance:</h4>
              <p>
                The difference between the trade-in value of a customer's old
                vehicle and the purchase price of a new vehicle, which may be
                used as a credit towards the new vehicle's purchase.
              </p>
              <br />

              <h4>15. Insurance:</h4>
              <p>
                Coverage purchased to protect against financial loss or damage
                to a vehicle, typically including liability, collision, and
                comprehensive coverage options.
              </p>
            </article>
          </section>
        </div>
      </section>
    </>
  );
};

export default Terms;
