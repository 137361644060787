import React from "react";
import Title from "./banner-image-1-1920x500.jpg";
const PageTitle = ({ fTeaxt, sText, para }) => {
  return (
    <section
      className="section section-bg"
      id="call-to-action"
      style={{
        backgroundImage: `url(${Title})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="cta-content">
              <br />
              <br />
              <h2>
                {fTeaxt} <em>{sText}</em>
              </h2>
              <p>{para}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
