import React, { useEffect, useState } from "react";
// import { useAuthStore } from "../store/store";
import { useParams, Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import axios from "axios";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  let { slug } = useParams();

  const [blog, setBlog] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
    getBlog();
  }, []);

  const getBlog = async () => {
    const res = await axios
      .post("https://backend.adraffmotorslimited.com/api/v1/blog/view", {
        blogSlug: slug,
      })
      .catch((err) => {
        console.log(err, err?.response?.data);
      });

    if (res) {
      const data = await res?.data;

      setBlog(data);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog?.title}</title>
        <link
          rel="canonical"
          href={`https://adraffmotorslimited.com/BlogDetails/${blog?.slug}`}
        />
        <meta property="og:title" content={blog?.title} />
        <meta property="og:description" content={blog?.content} />
        <meta property="og:image" content={blog?.image} />
      </Helmet>
      <PageTitle fTeaxt="SINGLE" sText="BLOG POST" para={blog?.title} />

      <section className="section" id="our-classes">
        <div className="container">
          <br />
          <br />
          <section className="tabs-content">
            <article>
              <h4>{blog?.title}</h4>
              <p>
                <i className="fa fa-user" /> {blog?.userName} &nbsp;|&nbsp;{" "}
                <i className="fa fa-calendar" />{" "}
                {new Date(blog?.createdAt).toLocaleString()} &nbsp;|&nbsp;{" "}
                <i className="fa fa-comments" /> 15 comments
              </p>
              <div>
                <img src={blog?.image} alt="blog img" />
              </div>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: blog?.content.replace(/(?:\r\n|\r|\n|\t)/g, "<br />"),
                }}
              ></p>

              <ul className="social-icons">
                <li>Share this:</li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-behance" />
                  </a>
                </li>
              </ul>
            </article>
          </section>
          <br />
          <br />
          <br />
          <section className="tabs-content">
            <div className="row">
              <div className="col-md-8">
                <h4>Comments</h4>
                <ul className="features-items">
                  <li>
                    <div className="feature-item" style={{ marginBottom: 15 }}>
                      <div className="left-icon">
                        <div
                          style={{
                            width: 100,
                            height: 100,
                            float: "left",
                            marginRight: 30,
                            borderRadius: 10,
                            backgroundColor: "#0043ff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="55"
                            height="55"
                            fill="#fff"
                            viewBox="0 0 256 256"
                          >
                            <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="right-content">
                        <h4>
                          John Doe <small>27.07.2020 10:10</small>
                        </h4>
                        <p>
                          <em>
                            "Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Dicta numquam maxime voluptatibus, impedit
                            sed! Necessitatibus repellendus sed deleniti id et!"
                          </em>
                        </p>
                      </div>
                    </div>
                    <div className="tabs-content">
                      <div
                        className="feature-item"
                        style={{ marginBottom: 15 }}
                      >
                        <div className="left-icon">
                          <div
                            style={{
                              width: 100,
                              height: 100,
                              float: "left",
                              marginRight: 30,
                              borderRadius: 10,
                              backgroundColor: "#0043ff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55"
                              height="55"
                              fill="#fff"
                              viewBox="0 0 256 256"
                            >
                              <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="right-content">
                          <h4>
                            John Doe <small>27.07.2020 11:10</small>
                          </h4>
                          <p>
                            <em>
                              "Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Dicta numquam maxime
                              voluptatibus, impedit sed! Necessitatibus
                              repellendus sed deleniti id et!"
                            </em>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="feature-item" style={{ marginBottom: 15 }}>
                    <div className="left-icon">
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          float: "left",
                          marginRight: 30,
                          borderRadius: 10,
                          backgroundColor: "#0043ff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="55"
                          height="55"
                          fill="#fff"
                          viewBox="0 0 256 256"
                        >
                          <path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="right-content">
                      <h4>
                        John Doe <small>27.07.2020 12:10</small>
                      </h4>
                      <p>
                        <em>
                          "Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Dicta numquam maxime voluptatibus, impedit sed!
                          Necessitatibus repellendus sed deleniti id et!"
                        </em>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h4>Leave a comment</h4>
                <div className="contact-form">
                  <form action method="post">
                    <div className="row">
                      <div className="col-lg-12">
                        <fieldset>
                          <input
                            name="name"
                            type="text"
                            id="name"
                            placeholder="Your Name*"
                            required
                          />
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <input
                            name="email"
                            type="text"
                            id="email"
                            pattern="[^ @]*@[^ @]*"
                            placeholder="Your Email*"
                            required
                          />
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <textarea
                            name="message"
                            rows={6}
                            id="message"
                            placeholder="Message"
                            required
                            defaultValue={""}
                          />
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <button
                            type="submit"
                            id="form-submit"
                            className="main-button"
                          >
                            Submit
                          </button>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
