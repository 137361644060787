import React, { useState } from "react";
import { Link } from "react-router-dom";

const HomeBLOG = ({ data }) => {
  const [currentBlog, setCurrentBlog] = useState("Bolg-1");

  return (
    <section className="section" id="our-classNamees">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
                Read our <em>Blog</em>
              </h2>
              <img src="assets/images/line-dec.png" alt="" />
              <p>
                Welcome to our blog section, where curiosity meets insight and
                knowledge ignites inspiration.
              </p>
            </div>
          </div>
        </div>
        <div className="row" id="tabs">
          <div className="col-lg-4">
            <ul>
              <li>
                <a href="#tabs-1" onClick={() => setCurrentBlog("Bolg-1")}>
                  {data[0]?.title}
                </a>
              </li>
              <li>
                <a href="#tabs-2" onClick={() => setCurrentBlog("Bolg-2")}>
                  {data[1]?.title}
                </a>
              </li>
              <li>
                <a href="#tabs-3" onClick={() => setCurrentBlog("Bolg-3")}>
                  {data[2]?.title}
                </a>
              </li>
              <div className="main-rounded-button">
                <Link to="/blog">Read More</Link>
              </div>
            </ul>
          </div>
          <div className="col-lg-8">
            <section className="tabs-content">
              {currentBlog === "Bolg-1" && (
                <article id="tabs-1">
                  <img src={data[0]?.image} alt="blogImg" />
                  <h4>{data[0]?.title}</h4>

                  <p>
                    <i className="fa fa-user"></i> {data[0]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(data[0]?.createdAt).toLocaleString()}
                    &nbsp;|&nbsp; <i className="fa fa-comments"></i> 15 comments
                  </p>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: data[0]?.content
                        .slice(0, 270)
                        .replace(/(?:\r\n|\r|\n|\t)/g, "<br />"),
                    }}
                  ></p>
                  <div className="main-button">
                    <Link to={`/BlogDetails/${data[0]?.slug}`}>
                      Continue Reading
                    </Link>
                  </div>
                </article>
              )}
              {currentBlog === "Bolg-2" && (
                <article id="tabs-2">
                  <img src={data[1]?.image} alt="blogImg" />
                  <h4>{data[1]?.title}</h4>
                  <p>
                    <i className="fa fa-user"></i> {data[1]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(data[1]?.createdAt).toLocaleString()}
                    &nbsp;|&nbsp; <i className="fa fa-comments"></i> 15 comments
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data[1]?.content
                        .slice(0, 270)
                        .replace(/(?:\r\n|\r|\n|\t)/g, "<br />"),
                    }}
                  ></p>
                  <div className="main-button">
                    <Link to={`/BlogDetails/${data[1]?.slug}`}>
                      Continue Reading
                    </Link>
                  </div>
                </article>
              )}
              {currentBlog === "Bolg-3" && (
                <article id="tabs-3">
                  <img src={data[2]?.image} alt="blogImg" />
                  <h4>{data[2]?.title}</h4>
                  <p>
                    <i className="fa fa-user"></i> {data[2]?.userName}{" "}
                    &nbsp;|&nbsp; <i className="fa fa-calendar"></i>{" "}
                    {new Date(data[2]?.createdAt).toLocaleString()}
                    &nbsp;|&nbsp; <i className="fa fa-comments"></i> 15 comments
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data[2]?.content
                        .slice(0, 270)
                        .replace(/(?:\r\n|\r|\n|\t)/g, "<br />"),
                    }}
                  ></p>
                  <div className="main-button">
                    <Link to={`/BlogDetails/${data[2]?.slug}`}>
                      Continue Reading
                    </Link>
                  </div>
                </article>
              )}
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBLOG;
