import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { IoLogoTiktok } from "react-icons/io5";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p>Copyright © 2024 Adraff Motors Limited</p>
          </div>
          <div className="col-lg-12">
            <Link
              to="https://x.com/adraffmotorsltd?t=lvGm1C1UEm8ExBao2nYVRw&s=09"
              style={{ marginRight: 10 }}
            >
              <FaSquareXTwitter size={20} color="#7a7a7a" />
            </Link>
            <Link
              to="https://www.facebook.com/adraffmotorslimited?mibextid=LQQJ4d"
              style={{ marginRight: 10 }}
            >
              <FaFacebookSquare size={20} color="#7a7a7a" />
            </Link>
            <Link
              to="https://www.instagram.com/adraffmotorslimited?igsh=MWpsZXAyY2lvcmlrYQ=="
              style={{ marginRight: 10 }}
            >
              <FaInstagramSquare size={20} color="#7a7a7a" />
            </Link>
            <Link
              to="https://youtube.com/@adraffmotorslimited?si=XyiuaspZq5rPTqrF"
              style={{ marginRight: 10 }}
            >
              <IoLogoYoutube size={20} color="#7a7a7a" />
            </Link>
            <Link
              to="https://www.tiktok.com/@adraffmotorsltd?_t=8lFApWBpTVu&_r=1"
              style={{ marginRight: 10 }}
            >
              <IoLogoTiktok size={20} color="#7a7a7a" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
