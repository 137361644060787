import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import OurClasses from "../components/AboutUs/OurClasses";

const AboutUs = () => {
  return (
    <>
      <PageTitle
        fTeaxt="LEARN MORE"
        sText="ABOUT US"
        para="Welcome to Adraff Motors Limited, where innovation meets excellence."
      />
      <OurClasses />
      <section
        className="section section-bg"
        id="call-to-action"
        style={{
          backgroundImage: "url(assets/images/banner-image-1-1920x500.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="cta-content">
                <h2>
                  Send us a <em>message</em>
                </h2>
                <p>
                  Whether you have questions, feedback, or inquiries, we're here
                  to assist you.
                </p>
                <div className="main-button">
                  <Link to="/contact">Contact us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
