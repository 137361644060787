import React, { useState } from "react";
import Header from "../components/Contact/Header";
import ContactFeatures from "../components/Contact/ContactFeatures";
import axios from "axios";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    alert("Please wait");
    const res = await axios
      .post("https://backend.adraffmotorslimited.com/contact-us", formData)
      .catch((err) => {
        console.log(err, err?.response?.data);
        alert("Message sending failed!");
      });

    if (res) {
      const data = await res?.data;
      console.log(data);
      alert("Message sent successfully!");
    }
  };
  return (
    <>
      <Header />
      <ContactFeatures />
      <section class="section" id="contact-us" style={{ marginTop: 0 }}>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <div id="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.5582057706233!2d7.445445473674028!3d9.103947987814005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b7c395bfd47%3A0x796752a5ca8824ec!2sADRAFF%20MOTORS!5e0!3m2!1sen!2sng!4v1712108899388!5m2!1sen!2sng"
                  width="100%"
                  height="600px"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  title="map"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12">
              <div
                class="contact-form section-bg"
                style={{
                  backgroundImage: "url(assets/images/contact-1-720x480.jpg)",
                }}
              >
                <form id="contact" onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="name"
                          type="text"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Your Name*"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="email"
                          type="text"
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                          pattern="[^ @]*@[^ @]*"
                          placeholder="Your Email*"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="subject"
                          type="text"
                          id="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          placeholder="Subject"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows="6"
                          id="message"
                          value={formData.message}
                          onChange={handleChange}
                          placeholder="Message"
                          required=""
                        ></textarea>
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          class="main-button"
                        >
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
