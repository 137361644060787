import React from "react";
import { Link } from "react-router-dom";

const HomeMessageUs = () => {
  return (
    <section
      className="section section-bg"
      id="call-to-action"
      style={{
        backgroundImage: "url(assets/images/banner-image-1-1920x500.jpg)",
        marginTop: 30,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="cta-content">
              <h2>
                Send us a <em>message</em>
              </h2>
              <p>
                Whether you have questions, feedback, or inquiries, you can get
                in touch with us for free.
              </p>
              <div className="main-button">
                <Link to="/Contact">Contact us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMessageUs;
