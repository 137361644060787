import React from "react";
import "./style.css";
const Skeleton = ({ width, height, variant }) => {
  const style = {
    width,
    height,
  };
  return <div className={`skeleton ${variant}`} style={style} />;
};

export default Skeleton;
