import React from "react";

const CarsCallToAction = () => {
  return (
    <section
      className="section section-bg"
      id="call-to-action"
      style={{
        backgroundImage: "url(assets/images/banner-image-1-1920x500.jpg)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="cta-content">
              <br />
              <br />
              <h2>
                Our <em>Cars</em>
              </h2>
              <p>
                Get ready to elevate your driving experience with our handpicked
                selection of premium vehicles. From sleek sedans to rugged SUVs,
                we've curated a collection that combines style, performance, and
                reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarsCallToAction;
