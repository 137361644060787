import React from "react";

const HomeAboutUs = () => {
  return (
    <section
      className="section section-bg"
      id="schedule"
      style={{
        backgroundImage: "url(assets/images/about-fullscreen-1-1920x700.jpg)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading dark-bg">
              <h2>
                Read <em>About Us</em>
              </h2>
              <img src="assets/images/line-dec.png" alt="" />
              <p>
                We prioritize the needs and preferences of our customers above
                all else, ensuring that every interaction is personalized and
                tailored to their requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-content text-center">
              <p>
                Adraff Motors Limited stands as a beacon of excellence in the
                automotive industry, offering unparalleled service and an
                extensive range of high-quality vehicles. Established with a
                vision to redefine the car buying experience, we pride ourselves
                on our commitment to customer satisfaction, professionalism, and
                integrity.
              </p>

              <p>
                At Adraff Motors Limited, our mission is simple: to provide our
                customers with the finest vehicles and exceptional service that
                exceed their expectations. We strive to create lasting
                relationships built on trust, transparency, and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAboutUs;
